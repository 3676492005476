<template>
    <div class="loader" :class="{ 'loader--fullscreen': fullscreen }" v-if="active">
        <video class="loader__video" ref="video" autoplay loop muted playsinline>
            <template v-if="isColor('green')">
                <source src="../../videos/loader/temp/hevc/green.mov" type='video/mp4; codecs="hvc1"'>
                <source src="../../videos/loader/temp/webm/green.webm" type="video/webm">
            </template>
            <template v-if="isColor('red')">
                <source src="../../videos/loader/temp/hevc/red.mov" type='video/mp4; codecs="hvc1"'>
                <source src="../../videos/loader/temp/webm/red.webm" type="video/webm">
            </template>
        </video>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            required: true,
            default: true
        },

        fullscreen: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: 'green'
        }
    },

    data() {
        return {
        }
    },

    methods: {
        isColor(color) {
            return this.color == color
        }
    },

    mounted() {
        let video = this.$refs.video
        if (video) {
            video.playbackRate = 1.7;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
