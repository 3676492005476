<template>
    <footer class="main-footer main-footer--default">
        <div class="main-footer__brand">
            <img class="main-footer__logo" src="../../images/brand/logo/cr/png/large/hm-logo-vertical-white.png" alt="Hajrá Magyarok">
            <div class="main-footer__tagline">A magyar virtuális sportmúzeum</div>
        </div>
        <div class="main-footer__social">
            <a href="https://www.instagram.com/hajramagyarok.hu/" class="main-footer__social__item" target="_blank"><img  class="main-footer__social__item__icon" src="../../images/social/instagram.svg" alt="Hajrá Magyarok Instagram"></a>
            <a href="https://www.facebook.com/hajramagyarokhu" class="main-footer__social__item" target="_blank"><img  class="main-footer__social__item__icon" src="../../images/social/facebook.svg" alt="Hajrá Magyarok Facebook"></a>
        </div>
        <nav class="main-footer__nav">
            <!-- <a href="/#/koszonto">Köszöntő</a> -->
            <a href="/#/kapcsolat">Kapcsolat</a>
            <a href="/#/gyakran-ismetelt-kerdesek">GYIK</a>
            <a href="/#/kozremukodok">Közreműködők</a>
            <!-- <a href="#">Impresszum</a> -->
            <a href="/#/adatvedelem">Adatvédelem</a>
        </nav>
    </footer>
    <div class="cookie-info" v-if="!cookieInfoAccepted">
		<div class="cookie-info__message">A weboldal cookie-kat használ. <a href="/#/adatvedelem" target="_blank">Információk</a></div>
		<button class="cookie-info__button" @click="acceptCookieInfo">Bezárás</button>
	</div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    data() {
        return {
            cookieInfoAccepted: false
        }
    },

    methods: {
        acceptCookieInfo() {
            Cookies.set('hm_cookie_info_accepted', 'accepted', { expires: 365 })
            this.cookieInfoAccepted = true
        }
    },

    mounted() {
        //this.cookieInfoAccepted = COOKIE_INFO_ACCEPTED || false
        // if (!Cookies.get('hm_cookie_info_accepted')) {
        // }
        this.cookieInfoAccepted = Cookies.get('hm_cookie_info_accepted') ? true : false
    }
}
</script>
