<template>
    <Header variant="t/p" hoist />

    <main class="search">
        <header class="search__header">
            <input class="search__input" type="text" autofocus v-model="term" @keydown.enter="search">
            <div class="search__actions">
                <button class="search__button" @click="search">Keresés</button>
            </div>
        </header>
        <div class="search__result wrapper">
            <div class="people-list" v-if="hasResults">
                <PersonCard :person="person" open-blank :key="person.id" v-for="person in results.people" />
            </div>

            <div class="search__no-result" v-if="!hasResults">Nincs megjeleníthető személy erre a kifejezésre.</div>
        </div>
    </main>

    <Footer />
</template>

<script>
import { useMainStore } from '../stores/main'
import axios from 'axios'
import ApiUrl from '../api_urls.js'
import Header from 'js/layout/header.vue'
import Footer from 'js/layout/footer.vue'
import PersonCard from 'js/people/person-card.vue'

export default {
    setup() {
        const store = useMainStore()
        return {
            store
        }
    },

    components: {
        Header,
        Footer,
        PersonCard
    },

    data() {
        return {
            term: null,
            results: {
                people: []
            }
        }
    },

    computed: {
        hasResults() {
            return this.results.people.length > 0
        }
    },

    methods: {
        search() {
            if (!this.term || this.term.length < 3) {
                return false
            }
            axios.post(ApiUrl('search'), {
                term: this.term
            })
                .then(response => {
                    this.results = response.data
                })
                .catch(error => console.log(error))
        }
    }
}
</script>
