import { niceDate } from 'js/nice_dates.js'

export default {
    methods: {
        // TODO: refactor signature
        getNiceDate(dateString, short = false, withoutDay = false) {
            return niceDate(dateString, short, withoutDay)
        }
    }
}
