<template>
    <article class="today" :class="{ 'today--obituary': pIsObituary }" @click="onClick">
        <div class="today__image" :style="`background-image: url('${pImage}');`" v-if="pImage"></div>
        <div class="today__drawer"></div>
        <div class="today__ago">
            <span class="today__ago__inner">{{pAgo}}<br>éve</span>
        </div>
        <header class="today__content">
            <div class="today__date-n-location">
                <div class="today__location">{{pLocation}}</div>
                <time class="today__date">{{pDate}}</time>
            </div>
            <h3 class="today__label">
                {{pLabel}}
                <!-- <div class="today__name">{{pName}}</div>
                <div class="today__event">{{pEvent}}</div> -->
            </h3>
        </header>
    </article>
</template>

<script>
export default {
    props: {
        pLocation: {
            type: String
        },

        pDate: {
            type: String
        },

        pAgo: {
            type: Number
        },

        pLabel: {
            type: String
        },

        // pName: {
        //     type: String
        // },

        // pEvent: {
        //     type: String
        // },

        pRoute: {
            type: Object
        },

        pPersonId: {
            type: String
        },

        pIsObituary: {
            type: Boolean
        },

        pImage: {
            type: String
        }
    },

    methods: {
        onClick() {
            if (this.pRoute) {
                this.$router.push(this.pRoute)
            }
            if (this.pPersonId) {
                window.open("/redir/p/" + this.pPersonId, '_blank')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
