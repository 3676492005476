<template>
    <Header />
    <Loader color="red" :active="isLoading" fullscreen />
    <main class="people wrapper">
        <h1 class="people__sport people__sport--alt" :class="{ 'people__sport--inactive': !hasCoaches }">Mesteredzők</h1>
        <button class="people__filter-btn button" @click="openFilterPane" v-show="!isLoading">Szűrés<span> és rendezés</span></button>
        <div class="people__container">
            <section class="people__list people-list">
                <PersonCard master-coach open-blank :person="person" :key="person.id" v-for="person in filteredCoaches" />
            </section>
        </div>
        <div class="people__no-results" v-if="!hasCoaches && !isLoading">
            Nincs megjeleníthető személy a jelenlegi szűrési feltételek szerint. <span class="people__no-results__action" @click="removeAllFilters">Szűrők törlése</span>
        </div>
    </main>

    <div class="filters-pane" :class="{ 'filters-pane--active': filterPane }">
        <button class="filters-pane__close" @click="closeFilterPane">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 67 67" style="enable-background:new 0 0 67 67;" xml:space="preserve">
                <g>
                    <path d="M2.1,67C0.9,67,0,66.1,0,64.9c0-0.6,0.2-1.1,0.6-1.5L63.4,0.6c0.8-0.8,2.1-0.8,3,0c0.8,0.8,0.8,2.1,0,3l0,0L3.6,66.4C3.2,66.8,2.6,67,2.1,67z M64.9,67c-0.6,0-1.1-0.2-1.5-0.6L0.6,3.6c-0.8-0.8-0.8-2.1,0-3s2.1-0.8,3,0l0,0l62.8,62.8c0.8,0.8,0.8,2.1,0,3C66,66.8,65.5,67,64.9,67z"/>
                </g>
            </svg>
        </button>
        <div class="filters-pane__container">
            <div class="filter-group">
                <label class="filter-group__label">Szűrők</label>
                <div class="filter-group__filters">
                    <button class="filter" :class="filterClass('gender', 'f')" @click="toggleFilter('gender', 'f')">Nő</button>
                    <button class="filter" :class="filterClass('gender', 'm')" @click="toggleFilter('gender', 'm')">Férfi</button>
                    <button class="filter" :class="filterClass('para', true)" @click="toggleFilter('para', true)">Para</button>
                </div>
            </div>
            <div class="filter-group">
                <label class="filter-group__label">Sportágak szerint</label>
                <div class="filter-group__filters">
                    <button class="filter" :class="filterClass('sport', sport[0])" @click="toggleFilter('sport', sport[0])" :key="sportId" v-for="sport in sports">{{ sport[1] }}</button>
                </div>
            </div>
            <div class="filters-pane__actions" v-if="hasAppliedFilter">
                <a class="filters-pane__action" @click="removeAllFilters">Összes szűrő törlése</a>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import PersonCard from 'js/people/person-card.vue'
import ApiUrl from '../api_urls.js'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const COLLATOR = new Intl.Collator('hu')

export default {
    components: {
        PersonCard
    },

    setup() {

    },

    data() {
        return {
            isLoading: true,
            coaches: [],
            filterPane: false,
            sports: [],
            filters: {
                sport: [],
                gender: [],
                para: []
            },
        }
    },

    computed: {
        filteredCoaches() {
            if (!this.coaches) {
                return []
            }
            let filtered = this.coaches

            // Filters
            if (this.filters.sport.length > 0) {
                filtered = filtered.filter(c => c.sport_ids.filter(sId => this.filters.sport.includes(sId)).length)
            }
            if (this.filters.gender.length > 0) {
                filtered = filtered.filter(c => this.filters.gender.includes(c.gender))
            }
            if (this.filters.para.length > 0) {
                filtered = filtered.filter(c => this.filters.para.includes(c.para))
            }

            // Sort
            filtered.sort((a, b) => b.master_coach_role_meta.master_coach_year - a.master_coach_role_meta.master_coach_year)

            return filtered
        },

        hasAppliedFilter() {
            let hasAppliedFilter = false
            for (const f in this.filters) {
                if (this.filters[f].length > 0) {
                    hasAppliedFilter = true
                    break;
                }
            }
            return hasAppliedFilter
        },

        hasCoaches() {
            return this.filteredCoaches.length > 0
        }
    },

    methods: {
        getCoaches() {
            axios.get(ApiUrl('master_coaches'))
                .then(response => {
                    this.coaches = response.data
                    this.initSportsFilter()
                    this.isLoading = false
                })
                .catch(error => {
                })
        },

        initSportsFilter() {
            let sports = {}
            this.coaches.forEach(coach => {
                coach.sports.forEach(sport => {
                    if (!sports[sport.id]) {
                        sports[sport.id] = sport.name
                    }
                })
            })
            this.sports = Object.entries(sports).sort((a, b) => COLLATOR.compare(a[1], b[1]))
        },

        openFilterPane() {
            this.filterPane = true
        },

        closeFilterPane() {
            this.filterPane = false
        },

        toggleFilter(filter, value) {
            if (this.filters[filter].includes(value)) {
                this.filters[filter] = this.filters[filter].filter(v => v !== value)
            } else {
                this.filters[filter].push(value)
            }
        },

        filterClass(filter, value) {
            return this.filters[filter].includes(value) ? "filter--applied" : ""
        },

        removeAllFilters() {
            for (const f in this.filters) {
                this.filters[f] = []
            }
        }
    },

    mounted() {
        this.getCoaches()
    }
}
</script>
