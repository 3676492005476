<template>
    <Header />

    <main class="competitions wrapper">
        <div class="competition-teaser" v-for="c in competitions">
            <div class="competition-teaser__coming-soon-wrapper">
                <div class="competition-teaser__coming-soon">Hamarosan</div>
            </div>
            <div class="competition-teaser__image">
                <div class="competition-teaser__year">{{c.year}}</div>
            </div>
            <h3 class="competition-teaser__name">{{c.display_name}}</h3>
            <div class="competition-teaser__location">{{c.location}}</div>
        </div>
    </main>

    <Loader :active="isLoading" fullscreen />

    <Footer />
</template>

<script>
//import { useMainStore } from '../stores/main'
import ApiUrl from '../api_urls.js'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export default {
    setup() {
        // const store = useMainStore()
        // return {
        //     store
        // }
    },

    data() {
        return {
            competitions: [],
            isLoading: true
        }
    },

    computed: {

    },

    methods: {
        getCompetitions() {
            axios.post(ApiUrl('competition_teasers'))
                .then(response => {
                    this.competitions = response.data
                    this.isLoading = false
                })
                .catch(error => {
                    //console.log(error)
                })
        }
    },

    mounted() {
        this.getCompetitions()
    }
}
</script>

<style lang="scss" scoped>

</style>
