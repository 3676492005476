<template>
    <Header />

    <main class="wrapper">
        <h1>Küldetésünk</h1>

        <p>A hajramagyarok.hu oldal célja az, hogy minden, magára valaha magyar válogatott mezt öltő sportoló megtalálható legyen a felületen.</p>
        <p>A vízió tiszta, a kihívás óriási.</p>
        <p>Az első lépéseket megtettük, az adatgyűjtés és azok feldolgozása elkezdődött, sőt, már tudunk mutatni részeredményeket is! Folyamatosan frissülő folyamatjelzőinken láthatjátok, hogy bizonyos szegmensekben éppen hogy állunk.</p>
        <p>Azonban minden segítségnek örülünk.</p>
        <p>Ha olyan információ, vagy adat birtokában vagy, amelyet szívesen megosztanál velünk, az <a href="mailto:info@hajramagyarok.hu">info@hajramagyarok.hu</a> email címen meg tudod tenni. Köszönjük!</p>

        <div class="progress">
            <h4 class="progress__title">Sportolók</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 50%;"></div>
                <div class="progress__bar__label">50%</div>
            </div>
        </div>
        <div class="progress">
            <h4 class="progress__title">Edzők</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 45%;"></div>
                <div class="progress__bar__label">45%</div>
            </div>
        </div>
        <div class="progress">
            <h4 class="progress__title">Eredmények</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 40%;"></div>
                <div class="progress__bar__label">40%</div>
            </div>
        </div>
        <div class="progress">
            <h4 class="progress__title">Események</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 30%;"></div>
                <div class="progress__bar__label">30%</div>
            </div>
        </div>
        <div class="progress">
            <h4 class="progress__title">Sportágak</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 80%;"></div>
                <div class="progress__bar__label" style="color: #fff;">80%</div>
            </div>
        </div>
        <div class="progress">
            <h4 class="progress__title">Képek és videók</h4>
            <div class="progress__bar">
                <div class="progress__bar__inner" style="width: 25%;"></div>
                <div class="progress__bar__label">25%</div>
            </div>
        </div>
    </main>

    <Footer />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    main {
        margin-top: 100px;
        text-align: center;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 auto 30px;
        padding: 0;
        line-height: 1.4;
    }

    a {
        color: #ff006e;
    }
</style>
