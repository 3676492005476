<template>
    <Header />

    <main class="wrapper wrapper--wide">
        <h1>Kapcsolat</h1>
        <p>
            Magyar Edzők Társasága<br>
            1146 Budapest, Istvánmezei út 1-3.<br>
            <a href="mailto:info@hajramagyarok.hu">info@hajramagyarok.hu</a>
        </p>
    </main>

    <Footer />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    main {
        margin-top: 100px;
        text-align: center;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 auto 30px;
        padding: 0;
        line-height: 1.4;
    }

    a {
        color: #ff006e;
    }
</style>
