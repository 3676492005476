<template>
    <Header />

    <div class="sports wrapper">
        <nav class="sport-cards sport-cards--standalone">
            <template :key="sport.id" v-for="sport in store.sports">
                <router-link class="sport-card sport-card--large" :to="{ name: 'people', params: { sportSlug: sport.slug } }" v-if="sport.has_published_person">
                    <span class="sport-card__label">{{sport.name}}</span>
                </router-link>
                <div class="sport-card sport-card--large sport-card--disabled" v-if="!sport.has_published_person">
                    <span class="sport-card__label">{{sport.name}}</span>
                    <div class="sport-card__coming-soon">Hamarosan</div>
                </div>
            </template>
        </nav>
    </div>

    <Loader :active="!hasSports" fullscreen />

    <Footer />
</template>

<script>
import { useMainStore } from '../stores/main'

export default {
    setup() {
        const store = useMainStore()
        return {
            store
        }
    },

    computed: {
        hasSports() {
            return this.store.sports.length > 0
        }
    },

    // data() {
    //     return {
    //     }
    // },

    mounted() {
        //this.store.getSports()
    }
}
</script>
