import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { createPinia } from 'pinia'

import App from 'js/app.vue'
import Home from 'js/home/index.vue'
import People from 'js/people/index.vue'
import Sports from 'js/sports/index.vue'
import Person from 'js/person/index.vue'
import Search from 'js/search/index.vue'
import Credits from 'js/pages/credits.vue'
import Greetings from 'js/pages/greetings.vue'
import Faq from 'js/pages/faq.vue'
import Contact from 'js/pages/contact.vue'
import Privacy from 'js/pages/privacy.vue'
import Mission from 'js/pages/mission.vue'
import CompetitionTeasers from 'js/competitions/teasers.vue'
import MasterCoaches from 'js/master_coaches/index.vue'
import Olympics from 'js/olympics/index.vue'
import Competition from 'js/competition/index.vue'
import Euro2024 from 'js/featured/euro2024/index.vue'
import Paris2024 from 'js/featured/paris2024/index.vue'
import Paris2024Sport from 'js/featured/paris2024/sport.vue'
import Paris2024Para from 'js/featured/paris2024para/index.vue'
import Paris2024ParaSport from 'js/featured/paris2024para/sport.vue'

import Loader from 'js/preloader/loader.vue'
import Header from 'js/layout/header.vue'
import Footer from 'js/layout/footer.vue'



const router = createRouter({
    history: createWebHashHistory(),

    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/sport/:sportSlug',
            name: 'people',
            component: People,
            props: true
        },
        {
            path: '/sportok',
            name: 'sports',
            component: Sports,
        },
        {
            path: '/sportolo/:personPath',
            name: 'person',
            component: Person,
            props: true
        },
        {
            path: '/s',
            name: 'search',
            component: Search,
            props: true
        },
        {
            path: '/kozremukodok',
            name: 'credits',
            component: Credits
        },
        {
            path: '/koszonto',
            name: 'greetings',
            component: Greetings
        },
        {
            path: '/gyakran-ismetelt-kerdesek',
            name: 'faq',
            component: Faq
        },
        {
            path: '/kapcsolat',
            name: 'contact',
            component: Contact
        },
        {
            path: '/adatvedelem',
            name: 'privacy',
            component: Privacy
        },
        {
            path: '/kuldetesunk',
            name: 'mission',
            component: Mission
        },
        {
            path: '/versenyek',
            name: 'competitions',
            component: CompetitionTeasers
        },
        {
            path: '/mesteredzok',
            name: 'masterCoaches',
            component: MasterCoaches
        },
        {
            path: '/olimpiak',
            name: 'olympics',
            component: Olympics
        },
        {
            path: '/verseny/:competitionPath',
            name: 'competition',
            component: Competition,
            props: true
        },
        {
            path: '/euro2024',
            name: 'euro2024',
            component: Euro2024
        },
        {
            path: '/paris2024',
            name: 'paris2024',
            component: Paris2024
        },
        {
            path: '/paris2024/:sportSlug',
            name: 'paris2024.sport',
            component: Paris2024Sport,
            props: true
        },
        {
            path: '/paris2024-paralimpia',
            name: 'paris2024para',
            component: Paris2024Para
        },
        {
            path: '/paris2024-paralimpia/:sportSlug',
            name: 'paris2024para.sport',
            component: Paris2024ParaSport,
            props: true
        },
    ],

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    }
})
const pinia = createPinia()

const app = createApp(App)
app.use(router)
app.use(pinia)

app
    .component('Loader', Loader)
    .component('Header', Header)
    .component('Footer', Footer)

app.mount('#hm-app')
