<template>
    <Header />

    <main class="wrapper wrapper--wide">
        <h1>Gyakran Ismételt Kérdések</h1>

        <h3>Mivel több ez, mint a Wikipédia?</h3>
        <p>A hajramagyarok.hu egyedi adatbázisa a hazai sportszövetségek, sportszervezetek, egyesületek, sportolók, szakértők és újságírók bevonásával épül. Minden adat és írás többszintű szűrésen esik át, így csak hiteles információ kerülhet ki a honlapra. Partnereink segítségével igyekszünk nem csak szövegesen, hanem képekkel és videókkal is bemutatni magyar válogatott sportolóinkat, és fantasztikus eredményeiket. Küldetésünk, hogy minden, valaha magyar válogatott színekben versenyző sportolót bemutassunk.</p>

        <h3>Miért nem találok meg minden sportágat?</h3>
        <p>Előfordulhat, hogy nem találod az általad keresett sportágat. Ez nem azért van, mert kifelejtettük, az oka az, hogy még nem került fel az adatbázisba egy olyan sportoló sem, aki a keresett sportágat képviseli. Ez a legtöbb esetben csak idő kérdése. Köszönjük a türelmed!</p>

        <h3>Miért nincs az oldalon minden válogatott sportoló?</h3>
        <p>Hatalmas adatmennyiséget dolgoznak fel munkatársaink, így időbe telik, míg mindenki sorra kerül. Köszönjük a türelmed!</p>

        <h3>Hiányosak az eredmények, vagy rossz adatot találtam, hol jelezhetem?</h3>
        <p>Természetesen hibázhatunk mi is, köszönjük, ha jelzed nekünk a <a href="mailto:info@hajramagyarok.hu">info@hajramagyarok.hu</a> email címen.</p>

        <h3>Miért nincsenek videók a versenyekről?</h3>
        <p>A videós tartalmakra a legtöbb esetben jóval szigorúbb és bonyolultabb szabályok vonatkoznak, mint a fotókra. Kollégáink azon dolgoznak, hogy több forrásból is becsatornázhassunk archív videós anyagokat.</p>

        <h3>Milyen tartalmak várhatók még?</h3>
        <p>Egy sportoló általában nem csak saját magának köszönheti a sikereit, ehhez szüksége van edzőkre, megfelelő sportvezetőkre, olyan háttérre, amely lehetővé tette számára, hogy kihozza magából a legtöbbet. Ezeket a személyeket is bemutatjuk majd a honlapon, illetve a nagy sporteseményeket, klubokat, emlékezetes momentumokat.</p>
    </main>

    <Footer />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    main {
        margin-top: 100px;
        text-align: center;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 auto;
        padding: 0;
        line-height: 1.4;
    }
</style>
