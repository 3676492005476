<template>
    <Header />

    <main class="wrapper wrapper--narrow">
        <h1 style="width: 70%; margin: 0 auto 30px;">Tisztelettel köszöntöm Önöket a Hajrá Magyarok, avagy a Magyar Virtuális Sportmúzeum honlapján!</h1>
        <p style="width: 70%; margin: 0 auto; text-align: center; font-size: 20px;">Azokról szól ez a weboldal, akikre büszkék vagyunk a magyar olimpiai mozgalom 127 éves története alatt elért fantasztikus sikerei miatt. Küldetésünk ezeknek a sportolóknak a bemutatása és az eredményeik hiánytalan feldolgozása, téli és nyári olimpiai, valamint paralimpiai sportok vonatkozásában.</p>

        <div class="video-block">
            <div class="video-block__container">
                <div class="video-wrapper">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Cz07cvHFEpQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <article>
            <p>A sikert természetesen nem csak helyezésben, de részvételben is mérjük, így minden olyan sportolót megtalálnak az oldalon, akik nem fényes éremmel a nyakukban tértek haza, de becsülettel képviselték Magyarországot a nemzetközi versenyeken.</p>
            <p>Kötelességemnek érzem, hogy megköszönjem a Magyar Kormány hathatós támogatását, az állami sporthivatal segítségét és azokét, akik nélkül ez a virtuális sportmúzeum nem jöhetett volna létre. Köszönöm a Magyar Olimpiai Bizottság, a Magyar Edzők Társasága, a Magyar Sportújságírók Szövetsége, a Magyar Sportmúzeum, a Magyar Olimpikonok Társasága, a Magyar Távirati Iroda és megannyi más sportszövetség, egyesület együttműködését. </p>
            <p>4500 sportoló szerepel itt, olyanok, akikkel most is kezet foghatunk bármikor, és olyanok is, akik már nem lehetnek közöttünk. Fontos mérföldkő, hogy ez a virtuális múzeum elindult, de itt nem fejezzük be a munkát, jönnek még majd a nem olimpiai sportok is. Nagyszerű sportembereket találunk ott is, de egyelőre fontos, hogy az olimpiai sportágak teljességében köszöntik Önöket.</p>
            <p>Kívánom Önöknek, hogy élvezzék, keressék meg kedvenceiket, látogassák azt a sportot, amelyet szeretnek. Mindent meg fognak tudni nagyszerű bajnokainkról, helyezettjeinkről, a magyar válogatottakról. </p>
            <p>Jó szórakozást kívánok!</p>
            <img class="signature" src="../../images/_content/greetings/schmitt-signiture.png" alt="">
        </article>
    </main>

    <Footer />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    main {
        margin-top: 100px;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
        text-align: center;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        width: 100%;
        margin: 0 auto 30px;
        padding: 0;
    }

    article {
        display: block;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
        font-size: 18px;
        font-family: 'RecifeText', Georgia, 'Times New Roman', Times, serif;
        line-height: 1.5;
    }

    .video-block {
        width: 80%;
        margin: 50px auto;
        padding: 30px;
        border-radius: 10px;
        background-color: var(--color-light-gray);

        &__container {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 1200px;
        }

        iframe {
            border-radius: 10px;
        }
    }

    .signature {
        display: block;
        width: 150px;
        height: auto;
    }
</style>
