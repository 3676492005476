<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
    competition: {
        type: Object,
        required: true
    }
})

const compImage = computed(() => {
    if (props.competition?.images?.logo) {
        return {
            url: props.competition.images.logo.urls.thumb_lg
        }
    } else if (props.competition?.images?.poster) {
        return {
            url: props.competition.images.poster.urls.thumb_lg
        }
    }
    return false
})

const cardClasses = computed(() => {
    let classes = []

    classes.push(`comp--${props.competition.competition_type}`)

    if (props.competition.competition_type == 'olympics' && props.competition.filterable_data?.olympics_season) {
        classes.push(`comp--olympics-${props.competition.filterable_data.olympics_season}`)
    }

    return classes
})

// function openComp() {
//     // if (this.openBlank) {
//     //     let route = this.$router.resolve({ name: 'person', params: { personPath: personPath } })
//     //     window.open(route.href, '_blank')
//     // } else {
//         $router.push({ name: 'competition', params: { competitionPath: competition.value.path } })
//     //}
// }
</script>



<template>
    <article class="comp" :class="cardClasses">
        <router-link class="comp__link" :to="{ name: 'competition', params: { competitionPath: competition.path } }">
            <div class="comp__visual">
                <img class="comp__visual__image" :src="compImage.url" :alt="competition.name" v-if="compImage">
                <time class="comp__year">
                    <span class="comp__year__number">{{ competition.year }}</span>
                </time>
            </div>
            <header class="comp__header">
                <h2 class="comp__name">{{ competition.name }}</h2>
                <div class="comp__location">{{ competition.location }}</div>
            </header>
        </router-link>
    </article>
</template>
