const MONTH_NAMES = ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december']
const MONTH_NAMES_SHORT = ['jan', 'feb', 'márc', 'ápr', 'máj', 'jún', 'júl', 'aug', 'szept', 'okt', 'nov', 'dec']

function niceDate(dateString, short = false, withoutDay = false) {
    if (!dateString) {
        return false
    }

    let monthNames
    if (short) {
        monthNames = MONTH_NAMES_SHORT.map(m => `${m}.`)
    } else {
        monthNames = MONTH_NAMES
    }

    const d = dateString.split("-")
    let niceDate = ""
    switch (d.length) {
        case 1:
            niceDate = dateString
            break
        case 2:
            niceDate = `${d[0]}. ${monthNames[parseInt(d[1]) - 1]}`
            break
        case 3:
            if (!withoutDay) {
                niceDate = `${d[0]}. ${monthNames[parseInt(d[1]) - 1]} ${parseInt(d[2])}.`
            } else {
                niceDate = `${d[0]}. ${monthNames[parseInt(d[1]) - 1]}`
            }
            break
    }

    return niceDate
}

export { niceDate }


