<script setup>
import { computed, onMounted, ref } from 'vue'
import ApiUrl from '../api_urls.js'
import axios from "axios"
import OlympicsCards from 'js/olympics/olympics-card.vue'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const isLoading = ref(true)
const competitions = ref([])

const hasCompetitions = computed(() => competitions.value.length > 0)

function getCompetitions() {
    axios.get(ApiUrl('competitions'))
        .then(response => {
            competitions.value = response.data
            isLoading.value = false
        })
        .catch(error => {
        })
}

onMounted(() => {
    getCompetitions()
})
</script>



<template>
<Header />
<Loader :active="isLoading" fullscreen />
<main class="competitions wrapper">
    <h1 class="competitions__sport" :class="{ 'competitions__sport--inactive': !hasCompetitions }">Olimpiák</h1>
    <!-- <button class="competitions__filter-btn button" @click="openFilterPane" v-show="!isLoading">Szűrés<span> és rendezés</span></button> -->
    <div class="competitions__container">
        <section class="competitions__list competitions-list">
            <OlympicsCards :competition="comp" :key="comp.id" v-for="comp in competitions" />
        </section>
    </div>
    <div class="competitions__no-results" v-if="!hasCompetitions && !isLoading">
        Nincs megjeleníthető verseny a jelenlegi szűrési feltételek szerint. <span class="competitions__no-results__action" @click="removeAllFilters">Szűrők törlése</span>
    </div>
</main>

<Footer />
</template>
