<template>
    <header class="header" :class="headerClasses">
        <div class="header__wrapper wrapper wrapper--wide">
            <a class="logo" href="#" @click.stop="goHome">
                <img class="logo__image" src="../../images/brand/logo/cr/png/large/hm-logo-horizontal-centered-pink.png" alt="Hajrá Magyarok" v-if="isLogoVariant('horizontal-centered-pink')">
                <img class="logo__image" src="../../images/brand/logo/cr/png/large/hm-logo-horizontal-centered-green.png" alt="Hajrá Magyarok" v-if="isLogoVariant('horizontal-centered-green')">
                <img class="logo__image" src="../../images/brand/logo/cr/png/large/hm-logo-horizontal-centered-teal.png" alt="Hajrá Magyarok" v-if="isLogoVariant('horizontal-centered-teal')">
                <img class="logo__image" src="../../images/brand/logo/cr/png/large/hm-logo-horizontal-centered-white.png" alt="Hajrá Magyarok" v-if="isLogoVariant('horizontal-centered-white')">
                <img class="logo__image" src="../../images/brand/logo/cr/png/large/hm-logo-vertical-gold-white.png" alt="Hajrá Magyarok" v-if="isLogoVariant('vertical-gold-white')">
            </a>
            <button class="menu" :class="menuBtnClasses" @click="toggleNav">
                <span class="menu__inner"></span>
            </button>
        </div>
        <nav class="nav" id="js-nav" :class="navClasses">
                <!-- <a class="nav__item" href="#">Sportolók</a> -->
                <!-- <a class="nav__item" href="#">Idővonal</a> -->

                <router-link class="nav__item" :to="{ name: 'home' }">Nyitóoldal</router-link>
                <router-link class="nav__item" :to="{ name: 'paris2024para' }">Paralimpia 2024</router-link>
                <router-link class="nav__item" :to="{ name: 'paris2024' }">Olimpia 2024</router-link>
                <!-- <router-link class="nav__item" :to="{ name: 'euro2024' }">Euro 2024</router-link> -->
                <router-link class="nav__item" :to="{ name: 'sports' }">Sportágak</router-link>
                <router-link class="nav__item" :to="{ name: 'masterCoaches' }">Mesteredzők</router-link>
                <!-- <router-link class="nav__item" :to="{ name: 'competitions' }">Versenyek</router-link> -->
                <router-link class="nav__item" :to="{ name: 'olympics' }">Olimpiák</router-link>
                <!-- <router-link class="nav__item" :to="{ name: 'mission' }">Küldetésünk</router-link> -->
                <!-- <router-link class="nav__item" :to="{ name: 'contact' }">Kapcsolat</router-link> -->
                <router-link class="nav__item" :to="{ name: 'greetings' }">Köszöntő</router-link>


                <!--<router-link class="nav__item" :to="{ name: 'people', params: { sportId: 'a8ff165f-2e9d-43c0-ab03-0ccd70e363fd' } }">Sportolók</router-link>-->
                <!-- <a class="nav__item" href="#">Menü elem 1</a>
                <a class="nav__item" href="#">Menü elem 2</a> -->
                <!-- <img  src="../../images/icons/search.svg"> -->
                <svg class="nav__search" @click="goToSearch" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75 75" style="enable-background:new 0 0 75 75;" xml:space="preserve">
                    <path d="M30.5,61.3C13.5,61.2-0.1,47.4,0,30.5C0.1,13.7,13.7,0.1,30.5,0l0,0c16.9,0,30.6,13.7,30.7,30.7l0,0C61.2,47.6,47.4,61.3,30.5,61.3z M30.5,4.7c-14.3,0-26,11.6-26,26s11.6,26,26,26c14.3,0,26-11.6,26-26c0,0,0,0,0,0l0,0C56.5,16.3,44.9,4.7,30.5,4.7L30.5,4.7L30.5,4.7z M72.5,75c-0.6,0-1.2-0.2-1.7-0.7l-22-22c-0.9-0.9-0.9-2.4,0-3.3c0.9-0.9,2.4-0.9,3.3,0l22,22c0.9,0.9,0.9,2.4,0,3.3C73.7,74.8,73.1,75,72.5,75z"/>
                </svg>
            </nav>
    </header>
</template>

<script>
import { lock, unlock } from 'tua-body-scroll-lock'

export default {
    props: {
        hoist: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String
        },
        logoVariant: {
            type: String
        }
    },

    data() {
        return {
            showNav: false,
            navElement: null
        }
    },

    computed: {
        headerClasses() {
            let classes = []
            if (this.hoist) {
                classes.push('header--hoist')
            }
            if (this.variant) {
                classes.push(`header--${this.variant}`)
            }
            return classes
        },

        menuBtnClasses() {
            return this.showNav ? "menu--close" : ""
        },

        navClasses() {
            return this.showNav ? "nav--open" : ""
        }
    },

    methods: {
        goHome() {
            this.$router.push({ name: 'home' })
            return false
        },

        goToSearch() {
            this.$router.push({ name: 'search' })
        },

        isLogoVariant(thisLogoVariant) {
            let logoVariant = "horizontal-centered-pink"
            if (this.logoVariant) {
                logoVariant = this.logoVariant
            }
            return thisLogoVariant == logoVariant
        },

        toggleNav() {
            this.showNav = !this.showNav
            if (this.showNav) {
                lock(this.navElement)
            } else {
                unlock(this.navElement)
            }
        }
    },

    mounted() {
        this.navElement = document.querySelector('#js-nav') // TODO: use refs
        unlock(this.navElement)
    }
}
</script>

<style>

</style>
