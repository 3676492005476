<template>
    <article class="teaser-card">
        <div class="teaser-card__portrait" :style="`background-image: url('${person.images.profile_pic.urls.preview_nano_sq}');`" v-if="person.images.profile_pic"></div>
        <header class="teaser-card__header">
            <!--<time class="teaser-card__years">{{person.year_of_birth}}<span v-if="person.year_of_death"> &mdash; {{person.year_of_death}}</span></time>-->
            <h2 class="teaser-card__name">{{person.name}}</h2>
            <div class="teaser-card__sport">{{person.sport_names.join(", ")}}</div>
        </header>
        <div class="teaser-card__drawer"></div>
        <div class="teaser-card__coming-soon">Hamarosan</div>
    </article>
</template>

<script>
export default {
    props: {
        person: {
            type: Object,
            required: true
        },
    },

    computed: {
    },

    methods: {
    }
}
</script>
