<template>
    <Header />

    <main class="wrapper wrapper--narrow">
        <h1>Adatkezelési tájékoztató a Magyar Virtuális Sportmúzeum kialakításához üzemeltetéséhez kezelt személyes adatok kapcsán</h1>

        <h2>A Magyar Virtuális Sportmúzeum kialakításának célja</h2>
        <p><strong>Az adatkezelés célja:</strong> A Magyar Virtuális Sportmúzeum kialakítása során felhasznált személyes adatok kezelésének célja a sportban vagy a sporthoz kapcsolódó tevékenységben kiemelkedő teljesítményt nyújtó személyek tevékenységének eredményeinek bemutatása a sportot szerető és a különböző sportágakat űző nagyközönség számára.</p>
        <p><strong>Jogalapja:</strong> A rendelet 6. cikk (1) f) pontja/ Az adatkezelő vagy egy harmadik fél jogos érdeke</p>
        <p><strong>Az adatok forrása:</strong> Az arcképcsarnokban bemutatott személy adatait az adatkezelő igyekszik közvetlenül az érintett-től beszerezni, ugyanakkor a cél elérése érdekében az érintett-től független harmadik személytől vagy adatkezelőtől is gyűjthet, gyűjt adatokat</p>
        <p><strong>Érintettek:</strong> kiemelkedő sport vagy a sporthoz kötődő kiemelkedő teljesítményt nyújtó személyek</p>
        <p>
            <strong>Kezelt adatok kategóriái:</strong>
            <ul>
                <li>Nyilvános felületen az arcképcsarnokhoz közvetlenül kapcsolódó személyes adatok</li>
                <li>Személyazonossághoz kapcsolódó adatok</li>
                <li>Azonosító adatok</li>
                <li>Képfelvétel</li>
                <li>Hangfelvétel</li>
            </ul>
            <ul>
                <li>az adatgyűjtéshez kapcsolódó csak az adatkezelő munkatársai számára és azon belül is a feladatkörüknél fogva az arra jogosultak által kezelt adatok</li>
                <li>Személyazonossághoz kapcsolódó adatok</li>
                <li>Elérhetőségi adatok</li>
                <li>Gazdasági, pénzügyi adatok</li>
            </ul>
        </p>
        <p>
            <strong>Kezelt adatok:</strong>
            <ul>
                <li>Név</li>
                <li>Születési hely és idő</li>
                <li>Végzettség</li>
                <li>Sportág</li>
                <li>Eredmények</li>
                <li>Kép és hangfelvételek</li>
                <li>Egyesületek</li>
                <li>Tevékenységhez kapcsolódó adatok</li>
            </ul>
        </p>
        <p><strong>Tárolási idő:</strong> 50 év, amely a tárolási idő lejárta előtt meghosszabbítható, vagy az adott személyes adatok kezelésének jogalapja a 6. cikk (1) e) pontjára hivatkozással átminősíthető az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat végrehajtásához szükséges adattá, amennyiben az adatkezelő közhatalmi jogosítványt kap tevékenysége révén.</p>

        <h2>Érintettek jogai</h2>
        <p>A Szervezet elsőszámú vezetője biztosítja, hogy az Érintett, a Szervezet által kezelt adataival kapcsolatban élhessen az Uniós rendeletben és a tagállami szabályozásban meghatározott jogaival. Az Érintettek jogaikat egyszerűsített, írásban benyújtott kérelem útján érvényesíthetik. Az Adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a kérelem beérkezésétől számított 30 napon belül tájékoztatja az Érintettet a kérelem nyomán hozott intézkedésekről. Szükség esetén, figyelembe véve a kérelem összetettségét és a kérelmek számát, ez a határidő további 60 nappal meghosszabbítható. A határidő meghosszabbításáról az Adatkezelő – a késedelem okainak megjelölésével – a kérelem kézhezvételétől számított 30 napon belül tájékoztatja az Érintettet. Ha az Érintett elektronikus úton nyújtotta be a kérelmet, a tájékoztatást lehetőség szerint elektronikus úton kell megadni, kivéve, ha az Érintett azt másként kéri.</p>
        <p>Az Érintett tájékoztatása, annak kérelme esetén, korlátozások kivételével, kiterjed a kezelt adatok és azok forrásának megjelölésére, az adatkezelés céljára, jogalapjára, időtartamára, a szervezet adatvédelmi felelősének nevére, elérhetőségi adataira. Külön kérésre az Adatkezelő tájékoztatja az Adatfeldolgozók és Címzettek adatairól az Érintettet. Az alapvető jogaival az Érintett ingyenesen élhet, de a felmerülő valós és arányos költségek tovább terhelhetők.</p>
        <p>Közös Adatkezelő esetében az adatok helyesbítéséről, zárolásáról vagy törléséről mindazokat tájékoztatni kell, akik az adatkezelésben érintettek, valamint azokat a Címzetteket, akiknek az adatot az adatátadási szabályzatnak megfelelően átadja az Adatkezelő.</p>

        <h3>Hozzáférés joga (betekintés)</h3>
        <p>A Szervezet elsőszámú vezetője biztosítja, hogy az Érintett (aki lehet a Szervezet tagja, tisztségviselője, munkavállalója) a Szervezet által kezelt adataihoz hozzáférjen.  A hozzáférést két módon lehet az érintett számára biztosítani.</p>
        <p>A Szervezet a kezelt adatokat elektronikus úton hozzáférhetővé teszi, ezt az Adatkezelő helyszínhez és feltételekhez kötheti.</p>
        <p>A kezelt adatokat elektronikus formában (elektronikusan hitelesítve) az Érintett rendelkezésére bocsátja, elektronikus tájékoztatás formájában megküldi.</p>
        <p>Az elektronikus tájékoztatás ingyenes, ha a tájékoztatást kérő az adott naptári évben azonos adatkezelésre vonatkozó tájékoztatási kérelmet még nem nyújtott be. Minden egyéb esetben a Szervezet a ráfordítással arányos költségtérítést állapíthat meg.</p>

        <h3>Helyesbítés joga és kötelezettsége</h3>
        <p>Az Érintett tájékoztatást kérhet a Szervezettől a személyes adatainak kezeléséről és kérheti személyes adatainak helyesbítését.</p>

        <h3>Törlés, felejtés joga</h3>
        <p>Az Érintett kérheti adatainak törlését, „elfelejtését” a feltételek fennállása esetén. A jogszabályi környezetben meghatározott kötelező adatkezelés előírásait kivéve. A törlés nem jelentheti csak a megjelenés korlátozását, valós fizikai törlést vagy helyreállíthatatlan felülírást jelenthet.</p>
        <p>A törlési, elfelejtési kérelemnek dokumentáltan eleget kell tenni, ha</p>
        <ul>
            <li>az adat kezelése jogellenes,</li>
            <li>az Érintett azt kéri, és az adatkezelés jogalapja a kérésre történt törlést lehetővé teszi</li>
            <li>az adat hiányos vagy téves, és ez az állapot jogszerűen nem orvosolható, feltéve, hogy az adatkezelés jogalapja a kérésre történt törlést lehetővé teszi</li>
            <li>az adatkezelés céljának megfelelő jogalaphoz kapcsolódó határidő lejárt</li>
            <li>azt a bíróság vagy az illetékes hatóság elrendelte.</li>
        </ul>

        <h3>Adatkezelés korlátozásának joga</h3>
        <p>Az Érintett kérheti kezelt adatainak adatkezelési korlátozását. Kivéve a jogszabályi környezetben meghatározott kötelező adatkezelés esetén. Ebben az esetben az adatok tárolás továbbra is fennáll, de az adatkezelés nem valósulhat meg.</p>

        <h3>Adathordozhatóság joga</h3>
        <p>Egy példány ingyenes, de az Adatkezelő felmerült költséget felszámolhat.</p>

        <h3>Tiltakozás joga</h3>
        <p>Az Érintett jogainak gyakorlása tekintetében, csak a közérdekű feladat kezelése és az Adatkezelő által jogos érdekké minősített jogalappal rendelkező adatkezelés (továbbítása) ellen élhet tiltakozással.</p>
        <p>A Szervezet a tiltakozást a kérelem benyújtásától számított legrövidebb időn belül, de legfeljebb 15 napon belül megvizsgálja, annak megalapozottsága kérdésében döntést hoz, és döntéséről a kérelmezőt írásban tájékoztatja.</p>
        <p>Amennyiben a tiltakozás indokolt, a Szervezet köteles az adatkezelést (további adatfelvételt, adattovábbítást) felfüggeszteni és az adatokat zárolni, valamint a tiltakozásról, és az annak alapján tett intézkedésekről értesíteni mindazokat, akik részére a tiltakozással érintett személyes adatot korábban továbbította, és akik kötelesek intézkedni a tiltakozási jog érvényesítése érdekében.</p>
        <p>Ha az Érintett nem ért egyet a tiltakozásának elbírálása eredményeként hozott döntéssel, illetve, ha a Szervezet a határidőt elmulasztotta, az Érintett –  a döntés közlésétől, illetve a határidő utolsó napjától számított 30 napon belül  –  az érvényes jogi környezetben meghatározott módon bírósághoz fordulhat.</p>

        <h3>Érintettek tájékoztatása</h3>
        <p>Az Érintettet az adatkezelés megkezdése előtt tájékoztatni kell az Infotv. 20.§-ában foglaltak szerint. Ez a tájékoztatás egyénileg írásban, valamint a Szervezet honlapján található „adatkezelés” menüpontban elhelyezett tájékoztató formájában is megtehető.</p>

        <h2>Az Érintett jogorvoslati lehetőségei</h2>
        <p>Amennyiben az Érintettet tiltakozása ellenére személyes adatai kezelésével kapcsolatban jogsérelem éri, úgy az alábbi jogorvoslati lehetőségekkel élhet:</p>
        <ul>
            <li>Tájékoztatást kérhet személyes adatai kezeléséről, valamint kérheti személyes adatainak helyesbítését</li>
            <li>Hozzájárulás jogalapján kezelt adatok tekintetében kérheti a személyes adatok törlését. Visszavonhatja hozzájárulását.</li>
            <li>Kérelmére tájékoztatást adunk az általunk kezelt, feldolgozott adatairól, az adatkezelés céljáról, jogalapjáról, időtartamáról.</li>
            <li>Az érintett személyes adatát töröljük, ha kezelése jogellenes, ha azt kéri, ha az adatkezelés célja megszűnt, ha az hiányos vagy téves, és ez az állapot jogszerűen nem korrigálható – feltéve, hogy a törlést törvény nem zárja ki – vagy az adatok tárolásának törvényben meghatározott határideje lejárt, azt a bíróság vagy az adatvédelmi tisztviselő elrendelte.</li>
        </ul>
        <p>Az Adatkezelő esetleges jogsértése ellen panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet élni:</p>
        <p>
            Nemzeti Adatvédelmi és Információszabadság Hatóság<br>
            1055 Budapest, Falk Miksa utca 9-11.<br>
            Levelezési cím: 1374 Budapest, Pf. 603.<br>
            E-mail: ugyfelszolgalat@naih.hu
        </p>
        <p>A panasztételi jogtól függetlenül az érintett bírósághoz is fordulhat személyes adatai jogellenes kezelése, illetve az információs önrendelkezési jogához kapcsolódó jogai sérelme miatt. Magyarországon a per az érintett lakóhelye vagy tartózkodási helye szerint illetékes törvényszék előtt, vagy az adatkezelő székhelye alapján illetékes bíróság előtt indítható meg. A lakóhelye vagy tartózkodási helye szerinti törvényszéket megkeresheti a https://birosag.hu/birosag-kereso oldalon.</p>
        <p>Az Adatkezelő fenntartja a jogot, hogy a jelen Tájékoztatót egyoldalúan, előzetes értesítés nélkül módosítsa.</p>
    </main>

    <Footer />
</template>


<style lang="scss" scoped>
    main {
        margin-top: 100px;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 0 30px 0;
        padding: 0;
        line-height: 1.4;
    }

    ul {
        max-width: 800px;
    }

    a {
        color: #ff006e;
    }
</style>
