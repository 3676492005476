<template>
    <Header />

    <main class="wrapper wrapper--narrow">
        <h1>Közreműködők</h1>

        <h2>A Magyar Virtuális Sportmúzeum létrehozását<br>segítették munkájukkal</h2>

        <h3>Magyar Edzők Társasága</h3>
        Molnár Zoltán, Ághassi Attila, Blázsovics-Petri Zsófia, Csabai Edvin, Dávid Viktória, Dikácz Ernő, Dobor Dezső, Farkas Judit, Fellerné Varga Hajnalka, Füredi Marianne, Horváth Rita, Kovács Mariann, Medvegy Iván, Ruthart Ildikó, Szabó Judit, Szabó Péter, Szentpéteri Fanni, Vincze Szabolcs, Vörös Tímea
        <h3>Magyar Olimpiai Bizottság &mdash; Magyar Olimpiai Akadémia</h3>
        Győr Béla
        <h3>Magyar Paralimpiai Bizottság</h3>
        Urr Anita, Borsos Dalma, Busa Andrea, Dányi Anita, Lajtos Lénárd, Oberfrank Mária, Sipos Borbála, Szabó Bence, Zoller Anna
        <h3>Magyar Olimpiai és Sportmúzeum</h3>
        Dr. Szabó Lajos
        <h3>MTVA ARCHÍVUM IRODA</h3>
        Borbély Judit, Répászky Lipót, Mizsei Erika
        <h3>MTVA &mdash; MTI SPORTSZERKESZTŐSÉG</h3>
        Ambrus Gábor
        <h3>FRADI MÚZEUM</h3>
        Tobak Csaba
        <h3>Magyar Asztalitenisz Szövetség</h3>
        Lajtai Kristóf, Both Levente, Fodor Róbert, Kovács László, Szentgyörgyi Róbert
        <h3>Magyar Biatlon Szövetség</h3>
        Bereczki Brigitta, Csák Örs
        <h3>Magyar Birkózó Szövetség</h3>
        Farkas Tibor, Jocha Károly, Klauz Vanessza, Mészáros Gyula, Deák Zsigmond, Krasznai Bence, Kohán Gergely, Róth Tamás
        <h3>Magyar Evezős Szövetség</h3>
        <p>Pignitzky Borbála, Kisfaludi Júlia, Dr. Alliquander Anna</p>
        <h3>Magyar Gyeplabda Szakszövetség</h3>
        <p>Ferenczi Zsolt</p>
        <h3>Magyar Íjász Szövetség</h3>
        <p>Windischmann Mercédesz Judit</p>
        <h3>Magyar Jégkorong Szövetség</h3>
        <p>Kramer Ádám</p>
        <h3>Magyar Judo Szövetség</h3>
        <p>Gombkötő Roland</p>
        <h3>Magyar Kajak-Kenu Szövetség</h3>
        <p>Kurilla Viktor</p>
        <h3>Magyar Karate Szakszövetség</h3>
        <p>dr. Tahon Róbert, Földi Rita</p>
        <h3>Magyar Kerékpáros Szövetség</h3>
        <p>Csepregi Hédi, Takács Gyula</p>
        <h3>Magyar Kézilabda Szövetség</h3>
        <p>Éliás Gábor, Liszkay Gábor, Palánki Alexandra</p>
        <h3>Magyar Kosárlabdázók Országos Szövetsége</h3>
        <p>Lugossy Norbert</p>
        <h3>Magyar Labdarúgó Szövetség</h3>
        <p>Ácsné Judit, Szabó Gergő</p>
        <h3>Magyar Lovassport Szövetség</h3>
        <p>Burucs Balázs</p>
        <h3>Magyar Műugró Szakszövetség</h3>
        <p>Linder Erzsébet</p>
        <h3>Magyar Országos Korcsolyázó Szövetség</h3>
        <p>Novák Antal, Simon Dorottya</p>
        <h3>Magyar Ökölvívó Szövetség</h3>
        <p>Sinkó Zoltán</p>
        <h3>Magyar Öttusa Szövetség</h3>
        <p>Király Anita, Martinek Patrícia</p>
        <h3>Magyar Röplabda Szövetség</h3>
        <p>Karádi Zoltán</p>
        <h3>Magyar Sakkszövetség</h3>
        <p>Bottlik Iván, Jakobetz László</p>
        <h3>Magyar Sí Szövetség</h3>
        <p>Bereczki Brigitta, Csák Örs</p>
        <h3>Magyar Snowboard Szövetség</h3>
        <p>Némethné Harmat Rózsa</p>
        <h3>Magyar Sportlövő Szövetség</h3>
        <p>Boros György, Horváth Ágnes, Pál-Plichta Györgyi, Pekler Zalán, Petró Sára, Pongrátzné Vasvári Erzsébet</p>
        <h3>Magyar Súlyemelő Szövetség</h3>
        <p>Jancsó Kornél</p>
        <h3>Magyar Taekwondo Szövetség</h3>
        <p>Patakfalvy Miklós, Kiss Roland</p>
        <h3>Magyar Tenisz Szövetség</h3>
        <p>Bor Péter</p>
        <h3>Magyar Tollaslabda Szövetség</h3>
        <p>Joó Melinda, Nebehaj Karola</p>
        <h3>Magyar Torna Szövetség</h3>
        <p>Altorjai Sándor, Dr. Karácsony István</p>
        <h3>Magyar Triatlon Szövetség</h3>
        <p>Balogh Júlia, Lipták Tamás, Király Anna</p>
        <h3>Magyar Úszó Szövetség</h3>
        <p>Csurka Gergely</p>
        <h3>Magyar Vitorlás Szövetség</h3>
        <p>Szekeres László</p>
        <h3>Magyar Vívó Szövetség</h3>
        <p>dr. Tamás Henriette, Csomós Dávid</p>
        <h3>Magyar Vízilabda Szövetség</h3>
        <p>Vezér Tamás, Babarczi Roland, Pucz Péter, Szűcs Zsolt, Szalánczi Krisztián</p>
        <h3>Olimpiai Hagyományőrző Egyesület</h3>
        <p>Halmay Zoltán</p>
        <h3>Magánszemélyek</h3>
        <p>Molnár István, Tóth Attila</p>
    </main>

    <Footer />
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    main {
        margin-top: 100px;
        text-align: center;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 auto;
        padding: 0;
        line-height: 1.4;
    }
</style>
