<script setup>
import { computed, onMounted, ref } from 'vue'
import ApiUrl from '../../api_urls.js'
import PersonCard from 'js/people/person-card.vue'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const isLoading = ref(true)
const featureds = ref([])

//const hasFeatureds = computed(() => featureds.value.length > 0)
const enabledFeatureds = computed(() => featureds.value.filter(f => f.featured_data.enabled))

const positionKapusok = computed(() => enabledFeatureds.value.filter(f => f.featured_data.position == "Kapus").sort((a, b) => a.featured_data.no - b.featured_data.no))
const positionVedok = computed(() => enabledFeatureds.value.filter(f => f.featured_data.position == "Védő").sort((a, b) => a.featured_data.no - b.featured_data.no))
const positionKozeppalyasok = computed(() => enabledFeatureds.value.filter(f => f.featured_data.position == "Középpályás").sort((a, b) => a.featured_data.no - b.featured_data.no))
const positionTamadok = computed(() => enabledFeatureds.value.filter(f => f.featured_data.position == "Támadó").sort((a, b) => a.featured_data.no - b.featured_data.no))
const positionSzovetsegiKapitany = computed(() => enabledFeatureds.value.filter(f => f.featured_data.position == "Szövetségi Kapitány").sort((a, b) => a.featured_data.no - b.featured_data.no))



function getFeatureds() {
    axios.get(ApiUrl('featured_people', null, 'context=euro2024'))
        .then(response => {
            featureds.value = response.data
            isLoading.value = false
        })
        .catch(error => {
        })
}



onMounted(() => {
    getFeatureds()
})
</script>



<template>
<Header variant="t/p" logo-variant="horizontal-centered-white" hoist />
<main class="football-page">
    <div class="football-hero">
        <img class="football-hero__image" src="../../../images/_content/football/euro2024-hero.png" alt="Euro 2024">
    </div>
    <div class="football wrapper">
        <header class="football-header">
            <!-- <img class="football-header__event-logo" src="../../../images/_content/football/euro2024-logo.png" alt="Euro 2024"> -->
            <h1 class="football-header__title">A magyar labdarúgó-válogatott kerete a 2024-es németországi Európa-bajnokságon</h1>
        </header>
        <section class="football-group">
            <h2 class="football-group__title">Kapusok</h2>
            <section class="people-list">
                <PersonCard football-player open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in positionKapusok" />
            </section>
        </section>
        <section class="football-group">
            <h2 class="football-group__title">Védők</h2>
            <section class="people-list">
                <PersonCard football-player open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in positionVedok" />
            </section>
        </section>
        <section class="football-group">
            <h2 class="football-group__title">Középpályások</h2>
            <section class="people-list">
                <PersonCard football-player open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in positionKozeppalyasok" />
            </section>
        </section>
        <section class="football-group">
            <h2 class="football-group__title">Támadók</h2>
            <section class="people-list">
                <PersonCard football-player open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in positionTamadok" />
            </section>
        </section>
        <section class="football-group">
            <h2 class="football-group__title">Szövetségi Kapitány</h2>
            <section class="people-list">
                <PersonCard football-player open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in positionSzovetsegiKapitany" />
            </section>
        </section>
    </div>
</main>
<Footer />
<Loader :active="isLoading" fullscreen />
</template>
