<script setup>
import { computed, onMounted, ref } from 'vue'
import ApiUrl from '../../api_urls.js'
import PersonCard from 'js/people/person-card.vue'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const COLLATOR = new Intl.Collator('hu')

const isLoading = ref(true)
const featureds = ref([])

//const hasFeatureds = computed(() => featureds.value.length > 0)
const enabledAndSortedFeatureds = computed(() => featureds.value.filter(f => f.featured_data.enabled)?.sort((a, b) => COLLATOR.compare(a.sport.name, b.sport.name)))


function getFeaturedSports() {
    axios.get(ApiUrl('featured_sports', null, 'context=paris2024para'))
        .then(response => {
            featureds.value = response.data
            isLoading.value = false
        })
        .catch(error => {
        })
}



onMounted(() => {
    getFeaturedSports()
})
</script>



<template>
<Header variant="t/p" logo-variant="horizontal-centered-white" hoist />
<main class="olympics-page">
    <header class="olympics-header">
        <router-link class="olympics-header__logo olympics-header__logo--para" :to="{ name: 'paris2024para' }">
            <img class="olympics-header__logo__image" src="../../../images/_content/olympics/paris2024-paralympics.png" alt="2024 Summer Paralympics">
        </router-link>
        <h1 class="olympics-header__title">A paralimpiai<br>csapat</h1>
    </header>

    <div class="olympics wrapper">
        <nav class="sport-cards">
            <router-link class="sport-card" :to="{ name: 'paris2024para.sport', params: { sportSlug: sport.sport.slug } }" :key="sport.id" v-for="sport in enabledAndSortedFeatureds">
                <span class="sport-card__label">{{ sport.sport.name }}</span>
            </router-link>
        </nav>
    </div>
</main>
<Footer />
<Loader :active="isLoading" fullscreen />
</template>
