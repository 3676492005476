<script setup>
import { computed, onMounted, ref } from 'vue'
import ApiUrl from '../../api_urls.js'
import PersonCard from 'js/people/person-card.vue'
import axios from "axios"
import { useMainStore } from '../../stores/main'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

const COLLATOR = new Intl.Collator('hu')

const props = defineProps({
    sportSlug: {
        type: String,
        required: true
    }
})

const store = useMainStore()

const isLoading = ref(true)
const featuredSports = ref([])
const featuredPeople = ref([])

const sport = computed(() => store.sports.find(s => s.slug == props.sportSlug))
//const hasFeatureds = computed(() => featureds.value.length > 0)
const enabledFeaturedSports = computed(() => featuredSports.value.filter(f => f.featured_data.enabled))
const currentFeaturedSport = computed(() => {
    if (sport.value) {
        return enabledFeaturedSports.value.find(f => f.sport.id == sport.value.id)
    }
})
const enabledFeaturedPeople = computed(() => featuredPeople.value.filter(f => f.featured_data.enabled))
const enabledFeaturedPeopleM = computed(() => enabledFeaturedPeople.value.filter(f => f.person.gender == 'm')?.sort((a, b) => COLLATOR.compare(a.person.name, b.person.name)))
const enabledFeaturedPeopleF = computed(() => enabledFeaturedPeople.value.filter(f => f.person.gender == 'f')?.sort((a, b) => COLLATOR.compare(a.person.name, b.person.name)))



function getFeaturedSports() {
    axios.get(ApiUrl('featured_sports', null, 'context=paris2024para'))
        .then(response => {
            featuredSports.value = response.data
        })
        .catch(error => {
        })
}

function getFeaturedPeople() {
    axios.get(ApiUrl('featured_people', null, `context=paris2024para&sport=${sport.value.id}`))
        .then(response => {
            featuredPeople.value = response.data
            isLoading.value = false
        })
        .catch(error => {
        })
}



onMounted(() => {
    getFeaturedSports()

    // Sad hack. Waitin' for the MainStore
    setTimeout(() => {
        getFeaturedPeople()
    }, 500)

})
</script>



<template>
<Header variant="t/p" logo-variant="horizontal-centered-white" hoist />

<main class="olympics-page">
    <header class="olympics-header">
        <router-link class="olympics-header__logo olympics-header__logo--para" :to="{ name: 'paris2024para' }">
            <img class="olympics-header__logo__image" src="../../../images/_content/olympics/paris2024-paralympics.png" alt="2024 Summer Paralympics">
        </router-link>
        <h1 class="olympics-header__title" v-if="!isLoading">
            <router-link class="olympics-header__title__link" :to="{ name: 'paris2024para' }">
                A paralimpiai<br>csapat
            </router-link>
            {{ sport.name }}
        </h1>
        <div class="olympics-header__bubble" v-if="!isLoading">{{ currentFeaturedSport.featured_data.number }} kvóta</div>
    </header>

    <div class="olympics wrapper" v-if="!isLoading">
        <div class="olympics-sport">
            <h2 class="olympics-sport__subtitle">{{ currentFeaturedSport.featured_data.subtitle }}</h2>
            <p class="olympics-sport__lead">{{ currentFeaturedSport.featured_data.lead }}</p>
        </div>

        <div class="olympics-group" v-if="enabledFeaturedPeopleF.length > 0">
            <h3 class="olympics-group__label">Nők</h3>
            <section class="people-list">
                <PersonCard olympics open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in enabledFeaturedPeopleF" />
            </section>
        </div>
        <div class="olympics-group" v-if="enabledFeaturedPeopleM.length > 0">
            <h3 class="olympics-group__label">Férfiak</h3>
            <section class="people-list">
                <PersonCard olympics open-blank :person="p.person" :featured-context="p.context" :featured-data="p.featured_data" :key="p.person.id" v-for="p in enabledFeaturedPeopleM" />
            </section>
        </div>


    </div>
</main>
<Footer />
<Loader :active="isLoading" fullscreen />
</template>

