const BASE_URL = "/api/v1/"
export default (endpoint, resource = null, paramsString = null) => {
    let url = BASE_URL + endpoint
    if (resource) {
        url += '/' + resource
    }
    if (paramsString) {
        url += '?' + paramsString
    }
    return url
}
