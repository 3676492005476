<template>
    <Header hoist variant="t/w" logo-variant="vertical-gold-white" />

    <Hero />
    <Subheroes />

    <!--<div class="wrapper">
        <div class="greetings">
        </div>
    </div>-->

    <section class="today-block" v-if="hasDailyEvents">
        <div class="today-block__wrapper wrapper">
            <h4 class="block-title today-block__title">Mi történt ezen a napon?</h4>
            <time class="today-block__date">{{this.displayMonthDay}}</time>
        </div>

        <div class="wrapper wrapper--wide">
            <div class="today-stripe">
                <TodayCard :pLocation="event.location" :pDate="event.display_event_date" :pAgo="event.years_ago" :pLabel="event.label" :pIsObituary="event.event_type == 'death'" :pPersonId="event.person_id" :pImage="event.image_url" :key="event.id" v-for="event in dailyEvents" />
            </div>
        </div>
    </section>

    <section class="sports wrapper">
        <h4 class="block-title">Sportágak</h4>
        <nav class="sport-cards">
            <template :key="sport.id" v-for="sport in store.sports">
                <router-link class="sport-card" :to="{ name: 'people', params: { sportSlug: sport.slug } }" v-if="sport.has_published_person">
                    <span class="sport-card__label">{{sport.name}}</span>
                </router-link>
                <div class="sport-card sport-card--disabled" v-if="!sport.has_published_person">
                    <span class="sport-card__label">{{sport.name}}</span>
                    <div class="sport-card__coming-soon">Hamarosan</div>
                </div>
            </template>
        </nav>
    </section>

    <!--
    <section class="overall-stats wrapper">
        <h4 class="block-title">A Hajrá Magyarok jelenlegi gyűjteménye számokban</h4>
        &mdash;overall stats&mdash;
    </section>
    -->

    <div class="temp-partners wrapper">
        <div class="label">Kiemelt támogató</div>
        <img src="../../images/_content/_demo/partner-1.png">
        <div class="label">Támogatók</div>
        <img src="../../images/_content/footer/partners-3.jpg">
    </div>

    <Footer />
</template>

<script>
import { useMainStore } from '../stores/main'
import Hero from './hero.vue'
import Subheroes from './subheroes.vue'
import TodayCard from '../today/today_card.vue'

export default {
    setup() {
        const store = useMainStore()
        return {
            store
        }
    },

    components: {
        Hero,
        Subheroes,
        TodayCard
    },

    data() {
        return {
            currentMonth: null,
            currentDay: null,
            displayMonthDay: "",
            currentMonthDayKey: null
        }
    },

    computed: {
        dailyEvents() {
            return this.store.dailyEvents[this.currentMonthDayKey]
        },

        hasDailyEvents() {
            return this.dailyEvents && this.dailyEvents.length > 0
        }
    },

    mounted() {
        document.body.classList.add('home')

        const date = new Date()
        this.currentMonth = date.getMonth() + 1
        this.currentDay = date.getDate()
        this.currentMonthDayKey = `${this.currentMonth}-${this.currentDay}`
        this.displayMonthDay = String(this.currentMonth).padStart(2, "0") + "/" + String(this.currentDay).padStart(2, "0")

        this.store.getDailyEvents(this.currentMonth, this.currentDay)
    },

    unmounted() {
        document.body.classList.remove('home')
    },
}
</script>

<style lang="scss" scoped>
    .temp-partners {
        display: block;
        margin-top: 200px;
        margin-bottom: 100px;
        text-align: center;

        div {
            display: block;
            margin: 100px 0 30px;
            font-size: 18px;
            font-weight: bold;
        }

        img {
            display: block;
            margin: 0 auto;
            height: auto;

            &:nth-of-type(1) {
                width: 160px;
                max-width: 100%;
            }

            &:nth-of-type(2) {
                width: 50%;
            }
        }

        .label {
            font-size: 16px;
        }
    }
</style>
