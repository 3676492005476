<template>
    <!-- <div class="search-pane"></div> -->
    <router-view></router-view>
</template>

<script>
import { useMainStore } from './stores/main'

export default {
    setup() {
        const store = useMainStore()

        // Request common data
        store.getSports()

        return {
            store
        }
    },
}
</script>
