import { defineStore } from "pinia"
import ApiUrl from '../api_urls.js'
import axios from "axios"

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector("meta[name=csrf-token]").content

export const useMainStore = defineStore('main', {
    state: () => ({
        sports: [],
        peopleBySport: {},
        teasersBySport: {},
        persons: {},
        dailyEvents: {}
    }),

    actions: {
        async getSports() {
            if (this.sports.length > 0) {
                return false
            }
            await axios.post(ApiUrl('sports'))
                .then(response => {
                    this.sports = response.data
                })
                .catch(error => {
                    //console.log(error)
                })
        },

        async getPeople(sportSlug) {
            if (this.peopleBySport[sportSlug] && this.peopleBySport[sportSlug].length > 0) {
                return false
            }
            await axios.post(ApiUrl('people_by_sport'), {
                sport_slug: sportSlug
            })
                .then(response => {
                    this.peopleBySport[sportSlug] = response.data
                })
                .catch(error => {
                    //console.log(error)
                })
        },

        async getTeasers(sportSlug) {
            if (this.teasersBySport[sportSlug] && this.teasersBySport[sportSlug].length > 0) {
                return false
            }
            await axios.post(ApiUrl('teasers_by_sport'), {
                sport_slug: sportSlug
            })
                .then(response => {
                    this.teasersBySport[sportSlug] = response.data
                })
                .catch(error => {
                    //console.log(error)
                })
        },

        async getDailyEvents(month, day) {
            const key = `${month}-${day}`
            if (this.dailyEvents[key] && this.dailyEvents[key].length > 0) {
                return false
            }

            await axios.post(ApiUrl('daily_events'), {
                month: month,
                day: day
            })
                .then(response => {
                    if (!this.dailyEvents[key]) {
                        this.dailyEvents[key] = []
                    }
                    this.dailyEvents[key] = response.data
                })
                .catch(error => {
                    //console.log(error)
                })
        },

        async getPerson(personPath) {
            if (this.persons[personPath]) {
                // !!!! TODO: it should return the person data
                return false
            }

            await axios.post(ApiUrl('person'), {
                path: personPath
            })
                .then(response => {
                    this.persons[personPath] = response.data
                })
                .catch(error => {
                    //console.log(error)
                })
        }
    },

    getters: {
    }
})
