<template>
    <Header variant="w/t" logo-variant="horizontal-centered-teal" />

    <Loader color="red" :active="isLoading" fullscreen />

    <main class="people wrapper" :class="{ 'people--has-index': showIndex }">
        <h1 class="people__sport" :class="{ 'people__sport--inactive': !hasPeople }">{{sportName}}</h1>

        <button class="people__filter-btn button" @click="openFilterPane" v-show="!isLoading">Szűrés<span> és rendezés</span></button>

        <div class="people__container">
            <nav class="people__index" v-if="showIndex">
                <a class="people__index__letter" :class="{ 'people__index__letter--selected': currentLetter == letter }" @click="toggleLetter(letter)" :key="'letters_' + letter" v-for="letter in letters">{{letter}}</a>
            </nav>
            <section class="people__list people-list">
                <PersonCard :person="person" :key="person.id" v-for="person in filteredPeople" />

                <template v-if="showTeasers">
                    <TeaserCard :person="person" :key="person.id" v-for="person in teasers" />
                </template>
            </section>
        </div>

        <div class="people__no-results" v-if="!hasPeople && !isLoading">
            Nincs megjeleníthető személy a jelenlegi szűrési feltételek szerint. <span class="people__no-results__action" @click="removeAllFilters">Szűrők törlése</span>
        </div>
    </main>

    <div class="filters-pane" :class="{ 'filters-pane--active': filterPane }">
        <button class="filters-pane__close" @click="closeFilterPane">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 67 67" style="enable-background:new 0 0 67 67;" xml:space="preserve">
                <g>
                    <path d="M2.1,67C0.9,67,0,66.1,0,64.9c0-0.6,0.2-1.1,0.6-1.5L63.4,0.6c0.8-0.8,2.1-0.8,3,0c0.8,0.8,0.8,2.1,0,3l0,0L3.6,66.4C3.2,66.8,2.6,67,2.1,67z M64.9,67c-0.6,0-1.1-0.2-1.5-0.6L0.6,3.6c-0.8-0.8-0.8-2.1,0-3s2.1-0.8,3,0l0,0l62.8,62.8c0.8,0.8,0.8,2.1,0,3C66,66.8,65.5,67,64.9,67z"/>
                </g>
            </svg>
        </button>


        <div class="filters-pane__container">

            <div class="filter-group">
                <label class="filter-group__label">Szűrők</label>
                <div class="filter-group__filters">
                    <button class="filter" :class="filterClass('gender', 'f')" @click="toggleFilter('gender', 'f')">Nő</button>
                    <button class="filter" :class="filterClass('gender', 'm')" @click="toggleFilter('gender', 'm')">Férfi</button>
                    <button class="filter" :class="filterClass('role', 'athlete')" @click="toggleFilter('role', 'athlete')">Sportoló</button>
                    <button class="filter" :class="filterClass('role', 'coach')" @click="toggleFilter('role', 'coach')">Edző</button>
                    <button class="filter" :class="filterClass('para', true)" @click="toggleFilter('para', true)">Para</button>
                </div>
            </div>

            <div class="filter-group">
                <label class="filter-group__label">Eredmények szerint</label>
                <div class="filter-group__filters">
                    <button class="filter" :class="filterClass('result', 'olympics_1')" @click="toggleFilter('result', 'olympics_1')">{{olympicsLabel}} bajnok</button>
                    <button class="filter" :class="filterClass('result', 'olympics_2')" @click="toggleFilter('result', 'olympics_2')">{{olympicsLabel}} ezüst</button>
                    <button class="filter" :class="filterClass('result', 'olympics_3')" @click="toggleFilter('result', 'olympics_3')">{{olympicsLabel}} bronz</button>
                    <button class="filter" :class="filterClass('result', 'wc_1')" @click="toggleFilter('result', 'wc_1')">Világbajnok</button>
                    <button class="filter" :class="filterClass('result', 'wc_2')" @click="toggleFilter('result', 'wc_2')">VB ezüst</button>
                    <button class="filter" :class="filterClass('result', 'wc_3')" @click="toggleFilter('result', 'wc_3')">VB bronz</button>
                </div>
            </div>

            <div class="filters-pane__actions" v-if="hasAppliedFilter">
                <a class="filters-pane__action" @click="removeAllFilters">Összes szűrő törlése</a>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import { useMainStore } from '../stores/main'
import PersonCard from 'js/people/person-card.vue'
import TeaserCard from 'js/people/teaser-card.vue'

const COLLATOR = new Intl.Collator('hu')

export default {
    props: {
        sportSlug: {
            type: String,
            required: true
        }
    },

    components: {
        PersonCard,
        TeaserCard
    },

    setup() {
        const store = useMainStore()
        return {
            store
        }
    },

    data() {
        return {
            filterPane: false,
            currentLetter: null,
            letters: [],
            isLoading: true,

            filters: {
                gender: [],
                result: [],
                role: [],
                para: [],
                result: []
            },
            teasers: [],
        }
    },

    computed: {
        people() {
            return this.store.peopleBySport[this.sportSlug]
        },

        filteredPeople() {
            if (!this.people) {
                return []
            }
            let filtered = []

            // Filter out ppl w/ missing last names
            filtered = this.people.filter(p => p.last_name)

            // Letter
            if (this.currentLetter) {
                filtered = filtered.filter(p => p.last_name[0].toLowerCase() == this.currentLetter)
            }

            if (this.filters.gender.length > 0) {
                filtered = filtered.filter(p => this.filters.gender.includes(p.gender))
            }

            if (this.filters.role.length > 0) {
                filtered = filtered.filter(p => this.filters.role.some(r => p.roles.includes(r)))
            }

            if (this.filters.para.length > 0) {
                filtered = filtered.filter(p => this.filters.para.includes(p.para))
            }

            if (this.filters.result.length > 0) {
                filtered = filtered.filter(p => this.filters.result.includes(p.champion_level))
            }

            return filtered
        },

        peopleCount() {
            return this.filteredPeople.length
        },

        unfilteredPeopleCount() {
            return this.people && this.people.length
        },

        hasPeople() {
            return this.peopleCount > 0
        },

        sportName() {
            if (this.store.sports.length > 0) {
                let sport = this.store.sports.find(s => s.slug == this.sportSlug)
                return sport.name
            }
        },

        showIndex() {
            return this.unfilteredPeopleCount >= 12 && this.letters.length > 0
        },

        hasAppliedFilter() {
            let hasAppliedFilter = false
            for (const f in this.filters) {
                if (this.filters[f].length > 0) {
                    hasAppliedFilter = true
                    break;
                }
            }
            if (!hasAppliedFilter && this.currentLetter) {
                hasAppliedFilter = true
            }
            return hasAppliedFilter
        },

        hasTeasers() {
            return this.teasers && this.teasers.length > 0
        },

        showTeasers() {
            return !this.hasAppliedFilter && this.hasTeasers
        },

        olympicsLabel() {
            return this.filters.para.includes(true) ? "Paralimpiai" : "Olimpiai"
        }
    },

    methods: {
        openFilterPane() {
            this.filterPane = true
        },

        closeFilterPane() {
            this.filterPane = false
        },

        toggleLetter(letter) {
            if (this.currentLetter && this.currentLetter === letter) {
                this.resetCurrentLetter()
            } else {
                this.currentLetter = letter
            }
        },

        populateLetters() {
            /*loller*/let ters = this.filteredPeople.map(p => p.last_name[0].toLowerCase())
            ters = [...new Set(ters)]
            ters.sort(COLLATOR.compare)
            this.letters = ters
        },

        addTeasers() {
            this.teasers = this.store.teasersBySport[this.sportSlug]
        },

        toggleFilter(filter, value) {
            this.resetCurrentLetter()
            if (this.filters[filter].includes(value)) {
                this.filters[filter] = this.filters[filter].filter(v => v !== value)
            } else {
                this.filters[filter].push(value)
            }
            this.populateLetters()
        },

        filterClass(filter, value) {
            return this.filters[filter].includes(value) ? "filter--applied" : ""
        },

        resetCurrentLetter() {
            this.currentLetter = null
        },

        removeAllFilters() {
            this.resetCurrentLetter()
            for (const f in this.filters) {
                this.filters[f] = []
            }
            this.populateLetters()
        }
    },

    mounted() {
        this.store.getPeople(this.sportSlug)
            .then(() => {
                this.populateLetters()
                this.isLoading = false

                this.store.getTeasers(this.sportSlug)
                    .then(() => this.addTeasers())
            })
    }
}
</script>
